<template>
  <div class="uchannel_list">
    <el-collapse accordion>
      <el-collapse-item v-for="item in all_uchannel_data" :key="item.carId" style="margin-top: 10px">
        <template slot="title">
          <i class="header-icon el-icon-info"></i>&nbsp;&nbsp;{{ item.carName }} &nbsp; {{item.type == 'group'? '群组': '频道'}}  | {{item.joinedChannelCount}} / {{item.joinChannelLimit}}
        </template>
        <el-table
            :data="item.uchannelList"
            style="width: 100%"
            align="center"
        >
          <el-table-column
              label="频道/群组名"
              align="left"
              prop="channelName">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.channelName }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="链接"
              align="center"
              prop="price">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium" type="success"><a target="_blank" style="text-decoration: none;" :href="scope.row.channelLink">{{ scope.row.channelLink }}</a></el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="人数"
              align="center"
              prop="stock">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.channelMember}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="类型"
              align="center"
              prop="method">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium" type="danger">{{ scope.row.type == 'group'? '群组': '频道' }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              align="center"
              prop="ops">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="danger"
                  @click="downCar(scope.$index, scope.row)">下车
              </el-button>
            </template>
          </el-table-column>

        </el-table>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>

export default {
  name: "u_channelList",
  data() {
    return {
      all_uchannel_data:[]
    }
  },

  created() {
    window.document.title = "频道&群组列表"
    this.getUChannelData()

  },

  methods: {

    getUChannelData(){
      this.httpRequest.get("back/u_channel/requestList")
          .then(response => {
            console.log(response)
            this.all_uchannel_data = response.data.uChannelVoList
          })
    },

    downCar(index, data){
      this.$confirm("确定要下车吗?", {
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            // console.log("群定了")
            this.httpRequest.post("back/u_channel/downCar/" + data.id).then(response => {
              this.getUChannelData()
            })
            done()
          } else {
            // console.log("取消了")
            done()
          }
        }
      })
    }


  }


}
</script>

<style scoped>
.addProduct {
  margin-left: 20px;
}

.queryProduct {
  width: 15%;
  position: absolute;
  right: 250px;
}

.queryButton {
  position: absolute;
  right: 400px;
}

.datatable {
  position: relative;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: rgb(96, 98, 102);
  overflow: hidden;
  flex: 1 1 0%;
}

.datatable td, .datatable th {
  padding: 12px 0;
  min-width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}


</style>